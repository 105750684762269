<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="通用套餐名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入通用套餐名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item
          label="标签"
          class="simiot-display-item"
        >
          {{ data.tags_display }}
        </a-form-item>

        <a-form-item
          label="共享属性"
          class="simiot-display-item"
        >
          {{ data.traffic_share_property_display }}
        </a-form-item>

        <a-form-item
          label="服务周期"
          class="simiot-display-item"
        >
          {{ data.service_cycle_display }}
        </a-form-item>

        <a-form-item
          label="套餐容量"
          class="simiot-display-item"
          v-if="isShowCapacityFields"
        >
          {{ data.package_capacity_display }}
        </a-form-item>

        <a-form-item label="语音" class="simiot-display-item">
          {{ data.voice_capacity_display }}
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { findBaseCarrierProductForm, updateBaseCarrierProduct } from '@/api/base_carrier_product'

export default {
  name: 'EditBaseCarrierProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'base_carrier_product' }),
      submitting: false,
      isShowCapacityFields: true,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findBaseCarrierProductForm(this.id).then((res) => {
        this.data = res.data
        this.isShowCapacityFields = res.data.traffic_share_property !== 'backward'
        this.form.setFieldsValue({ name: res.data.name })
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateBaseCarrierProduct(this.id, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
